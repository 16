import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Project from './Project';
import content from "../static/content";
import useWindowPosition from '../hook/useWindowPosition';

const useStyles = makeStyles((theme)=>({
  root: {
        minHeight:'100vh',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        [theme.breakpoints.down("md")]:{
            flexDirection :'column',
        }
  },
  title:{
        margin: '30px' ,
        textAlign:'center',
        fontFamily: 'Nunito',
        fontWeight:'regular',
  }

}));

export default function Projects() {
  const classes = useStyles();
  const checked = useWindowPosition('header');
  return (
    <div  id='projects'>
      <Typography variant="h4" className={classes.title}>Our Projects</Typography>
      <div className={classes.root}>
        <Project content={content[0]} checked={checked} />
        <Project content={content[1]} checked={checked} />
      </div>
    </div>
  )
}
