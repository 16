import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import { AppBar, Collapse, IconButton, Toolbar } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll} from 'react-scroll'
 
const useStyles = makeStyles((theme)=>({
  root:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'100vh',
    fontFamily:'Nunito',
    backgroundImage: `url(${process.env.PUBLIC_URL+"assets/backgrd.jpg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize :'cover',
    [theme.breakpoints.down("sm")]:{
      backgroundImage: `url(${process.env.PUBLIC_URL+"assets/backgrd_sm.jpg"})`,
  }
  },
  appbar:{
      background:"none",
      fontFamily:'Nunito',
  },
  appbarWrapper:{
    width:'80%',
    margin:'0 auto',
  },
  appbarTitle:{
      flexGrow:'1',
},
  icon:{
      color:'#fff',
      fontSize:'2rem',
  },
  colorText:{
    color:'#01c3fc',
    },
  
  container:{
      textAlign:'center',
  },
  title:{
    color:'#fff',
    fontSize:'2.8rem',
    [theme.breakpoints.down("sm")]:{
      fontSize:'1.8rem',
    },
        },
  
  subtitle:{
    color:'#fff',
    fontSize:'1.5rem',
    marginBottom:'5.8rem',
  },
  goDown:{
      color:'#01c3fc',
      fontSize:'4rem',
  },  
  
  
}));

export default function Header() {
  const classes = useStyles();
  const [checked, setChecked]= useState(false);
  useEffect(() => {
      setChecked(true);
  }, [])
  return (
    <div className={classes.root} id="header">
      <AppBar className={classes.appbar} elevation={0}>
          <Toolbar className={classes.appbarWrapper}>
            <h1 className={classes.appbarTitle}>
                <span className={classes.colorText} >u</span>nbiaz</h1>
            <IconButton>
                <SortIcon className={classes.icon}/>
            </IconButton>
          </Toolbar>          
      </AppBar>
      <Collapse in={checked} {...(checked?{timeout:1000}:{})} collapsedHeight={50}>
        <div className={classes.container} >
          <h1 className={classes.title}>
          <span className={classes.colorText} >Empowering</span> Small Businesses &amp; Individuals <br/> to Create<span className={classes.colorText} > Value</span> Through <span className={classes.colorText} > New Technologies</span>  
          </h1>
          {/* <p className={classes.subtitle}>Small Businesses &amp; Individuals</p> */}
          <Scroll to='projects' smooth={true}>
            <IconButton>
                <ExpandMoreIcon className={classes.goDown}/>
            </IconButton>
          </Scroll>
          
        </div>
      </Collapse>
      
    </div>
  )
}

