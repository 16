import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Collapse } from '@material-ui/core';
// import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  root: {
    maxWidth:500,
    background:'rgb(1,195,252)',
    margin:'20px',
  },
  media: {
    height: 440,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight:'bold',
    fontSize:'2rem',
    color:'#fff',
  },
  desc: {
    fontFamily: 'Nunito',
    fontSize:'1.1rem',
    color:'#000',
  },
  anchor:{
    textDecoration:'none',
    fontSize:'0.85rem',
    color:'#fff',
    // color:'#35b',
  },
  link:{
    fontSize:'0.85rem',
    color:'#fff',
  }
});

export default function Project({content, checked}) {
  const classes = useStyles();
  // const preventDefault = (event) => event.preventDefault();
  return (
      <Collapse in={checked} {...(checked?{timeout:1000}:{})}>
            <Card className={classes.root}>
      
                <CardMedia
                    className={classes.media}
                    image={content.imageUrl}
                //   image={process.env.PUBLIC_URL+"assets/topglobe_image.jpg"}
                    title="Unbiaz projects"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h1" className={classes.title}>
                    {content.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.desc}>
                    {content.description}
                    </Typography>
                </CardContent>
    
                <CardActions>
                
                { content.url ? (<a href={content.url}  className={classes.anchor} target="_blank" rel="noopener noreferrer">Go to website...</a>):(<Typography className={classes.link}>Coming Soon...</Typography>)}
                {/* <Button size="small" color="primary"></Button> onClick={preventDefault}*/}
                </CardActions>
            </Card>
      </Collapse>
    
  );
}