import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Header from './Components/Header';
import Projects from './Components/Projects';

const useStyles = makeStyles((theme)=>({
  root: {
    minHeight : '100vh',
  //   backgroundImage: `url(${process.env.PUBLIC_URL+"assets/backgrd.jpg"})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize :'cover',
  //   [theme.breakpoints.down("sm")]:{
  //     backgroundImage: `url(${process.env.PUBLIC_URL+"assets/backgrd_sm.jpg"})`,
  // }
  }

}));

export default function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Projects />
    </div>
  )
}

