const content = [
    {
      title: 'Topglobe',
      description:
        `We are assisting this UK N1 educational globe supplier to optimise their digital marketing using AI-powered Google Ads. 
        `,
      imageUrl: process.env.PUBLIC_URL + '/assets/topglobe_image2.jpeg',
      time: 1500,
      url:'https://topglobe.co.uk/',
    },
    {
      title: 'KidLancers Academy',
      description:
        'We offer a role-based training in programming and technologies for teens to foolproof their future as the next generation of tech leaders.',
      imageUrl: process.env.PUBLIC_URL + '/assets/kidlancer_image.png',
      time: 1500,
      url:'https://kidlancers.com/',
    },
  ];
//   Fisrt, we spearheaded the development of their ecommerce platform using Shopify. Second we are using machine learning ads tool, Google Smart Shopping
//         in the optimisation of their ads campaign.
  export default content;